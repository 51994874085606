import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from './modal_wrapper'
import { Address, OrderCreate, RegionShippingService, SelectField } from '../types'
import { regionShippingServicesRepository } from '../repositories/region_shipping_services_repository'
import { AxiosError } from 'axios'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { addressesRepository } from '../repositories/addresses_repository'
import { ErrorsContext } from '../contexts/errors_context'
import { AuthContext } from '../contexts/auth_context'
import { BasketContext } from '../contexts/basket_context'
import { UnitOrderSummary } from './unit_order_summary'
import Select from 'react-select'

// type OrderCreate = Readonly<{
//   address?: Address
//   regionShippingService?: RegionShippingService
//   listings?: Listing[]
//   addressField?: SelectField
//   regionShippingServiceField?: SelectField
//   returnPath?: string
//   feedbacks?: Feedback[]
//   referral?: string
// }>

const minimumPriceForStripe = 50

type AddressField = Readonly<{
  value: number
  label: string
  default: boolean
}>

const UnitBuyIntentModal = () => {
  const { t } = useTranslation()
  const reactLocation = useLocation()

  const { addError } = useContext(ErrorsContext)
  const { isAuthenticated, sessionId, currentUser } = useContext(AuthContext)
  const { basketUnits, setShowOrderAddress, setShowBasket, setPreparedOrder, referral } = useContext(BasketContext)

  const [addresses, setAddresses] = useState<Address[]>()
  const [addressesLoading, setAddressesLoading] = useState<boolean>(true)
  const [regionShippingServices, setRegionShippingServices] = useState<RegionShippingService[]>()
  const [regionShippingServicesLoading, setRegionShippingServicesLoading] = useState<boolean>(true)

  const {
    control,
    handleSubmit,
    watch,
    register,
    setValue,
  } = useForm()

  const watchAddressField = watch('addressField')
  const watchRegionShippingServiceField = watch('regionShippingServiceField')

  const regionShippingServiceField = useWatch({
    control,
    name: 'regionShippingServiceField',
  })

  const addressField = useWatch({
    control,
    name: 'addressField',
  })

  const points = useWatch({
    control,
    name: 'points',
  })

  const addressRequired = useMemo(() => {
    // determine if a basket item requires an address
    return basketUnits.some(basketUnit => basketUnit.unit.items?.some(item => item.type !== 'DigitalDownload'))
  }, [basketUnits])

  useEffect(() => {
    if (!addressRequired) setAddressesLoading(false)
  }, [addressRequired])

  const getRegionShippingServicesForUser = useCallback(() => {
    if (basketUnits.length === 0) return

    const userId = basketUnits[0].unit.userId ?? 0

    regionShippingServicesRepository
      .indexForUser(userId)
      .then(({ regionShippingServices }) => {
        setRegionShippingServices(regionShippingServices)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setRegionShippingServicesLoading(false)
      })
  }, [addError, basketUnits])

  useEffect(() => {
    if (isAuthenticated && addressRequired) {
      getRegionShippingServicesForUser()
    }
  }, [addressRequired, getRegionShippingServicesForUser, isAuthenticated])

  const getAddresses = useCallback(() => {
    setAddressesLoading(true)

    addressesRepository
      .index()
      .then(({ addresses }) => {
        setAddresses(addresses)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setAddressesLoading(false)
      })
  }, [addError])

  useEffect(() => {
    if (isAuthenticated && addressRequired) {
      getAddresses()
    }
  }, [addressRequired, getAddresses, isAuthenticated])

  useEffect(() => {
    // set default address after addresses have loaded
    if (!addresses) return

    const selectedAddress = addresses?.find(a => a.default)
    setValue('addressField', selectedAddress?.id)
  }, [addresses, setValue, watchAddressField])

  const selectedAddress = useMemo<Address|undefined>(() => {
    return addresses?.find(a => a.id === parseInt(watchAddressField))
  }, [addresses, watchAddressField])

  const availableShippingMethods = useMemo(() => {
    if (!selectedAddress) return []

    return regionShippingServices?.filter(r => selectedAddress.regionId !== r.region.id ? null : r)
  }, [regionShippingServices, selectedAddress])

  useEffect(() => {
    // if no region shipping service is selected, set the cheapest option
    if (!availableShippingMethods || availableShippingMethods.length < 1) return

    if (!watchRegionShippingServiceField) {
      const cheapest = availableShippingMethods?.reduce((prev, current) => prev.priceJpy < current.priceJpy ? prev : current)

      setValue('regionShippingServiceField', cheapest.id)
    }
  }, [availableShippingMethods, setValue, watchRegionShippingServiceField])

  useEffect(() => {
    // reset selected region shipping service if service is unavailable for region
    if (!watchRegionShippingServiceField || !availableShippingMethods || availableShippingMethods.length < 1) return

    if (!availableShippingMethods?.find(m => m.id === parseInt(watchRegionShippingServiceField))) {
      setValue('regionShippingServiceField', undefined)
    }
  }, [availableShippingMethods, setValue, watchRegionShippingServiceField])

  const selectedRegionShippingService = useMemo(() => {
    if (!watchRegionShippingServiceField) return

    return availableShippingMethods?.find(a => a.id === parseInt(watchRegionShippingServiceField))
  }, [watchRegionShippingServiceField, availableShippingMethods])

  const totalPrice = useMemo(() => {
    const i = basketUnits
      .map((basketUnit) => {
        const listing = basketUnit.unit.listings?.find(l => l.platform === 'holic')
        return (listing?.priceJpy ?? 0) * basketUnit.quantity
      })

    let price = 0

    if (i.length > 0) {
      price = i.reduce((total, next) => +total + +next)
    }

    if (addressRequired) {
      if (!selectedRegionShippingService) return

      return +price + +selectedRegionShippingService.priceJpy
    }

    return price
  }, [addressRequired, basketUnits, selectedRegionShippingService])

  const paymentMode = useMemo(() => {
    return basketUnits.some(basketUnit => basketUnit.mode === 'subscription') ? 'subscription' : 'payment'
  }, [basketUnits])

  const onSubmit = useCallback((_params) => {
    if (!totalPrice || !currentUser) return

    if (addressRequired && (!selectedAddress || !selectedRegionShippingService)) return

    let p = points ?? 0

    // make sure points are not greater than total price
    // also make sure points are not greater than user's points
    // show error if either of these conditions are true
    if (points > totalPrice) {
      // set points to total price
      p = totalPrice
    } else if (points && points > currentUser?.points) {
      alert(t('checkout.errors.points_mismatch'))
      return false
    }

    // TODO: Validation
    const order: OrderCreate = {
      points: p,
      address: selectedAddress,
      regionShippingService: selectedRegionShippingService,
      returnPath: reactLocation.pathname,
      referral,
      mode: paymentMode,
    }

    setPreparedOrder?.(order)
    setShowOrderAddress?.(false)
  }, [addressRequired, currentUser, points, reactLocation.pathname, referral, selectedAddress, selectedRegionShippingService, setPreparedOrder, setShowOrderAddress, t, totalPrice])

  const shippingMethodFields = useMemo<SelectField[]>(() => {
    if (!availableShippingMethods) {
      return []
    }

    return availableShippingMethods.map(a => {
      const sameMethods = availableShippingMethods.filter(m => m.shippingService.id === a.shippingService.id)

      let appendText = sameMethods.length > 1
        ? ` ${availableShippingMethods.findIndex(m => m.id === a.id) + 1}`
        : ''

      appendText += appendText ? a.description ? ' (see notes)' : '' : ''

      const r: SelectField = {
        label: `${t(`account.sell.shipping_options.options.${a.shippingService.label}`)}${appendText}: ${t('listings.price.price_with_currency_symbol', { price: Math.round(a.priceJpy) })}`,
        value: Number(a.id)
      }
      return r
    })
  }, [availableShippingMethods, t])

  const shippingAvailable = useMemo(() => availableShippingMethods && availableShippingMethods.length > 0, [availableShippingMethods])

  const selectedRegionShippingServiceDescription = useMemo(() => {
    if (!selectedRegionShippingService || !selectedRegionShippingService.description) return

    return <p>{ t('checkout.buy_intent.shipping_method_notes') }{ selectedRegionShippingService.description }</p>
  }, [selectedRegionShippingService, t])

  const shippingMethods = useMemo(() => {
    if (!addressRequired) return

    if (!addresses || addresses.length === 0) return

    if (regionShippingServicesLoading) {
      return (
        <p>
          <label>
            { t('checkout.buy_intent.shipping_method_label') }
          </label>
          { t('checkout.buy_intent.shipping_methods_loading') }
        </p>
      )
    }

    if (!shippingAvailable) {
      return (
        <p>{ t('checkout.buy_intent.shipping_unavailable') }</p>
      )
    }

    return (
      <>
        <div className='select'>
          <label>
            { t('checkout.buy_intent.shipping_method_label') }
          </label>
          <Controller
            control={ control }
            name='regionShippingServiceField'
            render={ ({ field }) => (
              <Select
                options={ shippingMethodFields }
                onChange={ val => field.onChange(val?.value) }
                placeholder={ t('defaults.select') }
                value={ shippingMethodFields.find(t => t.value === regionShippingServiceField) }
                defaultValue={ shippingMethodFields[0] }
              />
            ) }
          />
          {
          /* <Controller
            as={ Select }
            name="regionShippingServiceField"
            options={ shippingMethodFields }
            control={ control }
            rules={ { required: 'Please select an option' } }
            // onChange={ ([selected]) => {
            //   return { value: selected }
            // } }
            placeholder={ t('defaults.select') }
          /> */
          }
        </div>
        { selectedRegionShippingServiceDescription }
      </>
    )
  }, [addressRequired, addresses, regionShippingServicesLoading, shippingAvailable, t, control, selectedRegionShippingServiceDescription, shippingMethodFields, regionShippingServiceField])

  const addressFields = useMemo<AddressField[]>(() => {
    if (!addresses) {
      return []
    }

    return addresses.map(a => {
      const label = `${a.zipCode ?? ''} ${a.name}`
      const r: AddressField = {
        label,
        value: Number(a.id),
        default: a.default,
      }
      return r
    })
  }, [addresses])

  const payButtonDisabled = useMemo(() => {
    if (addressRequired) {
      if (!selectedAddress || !selectedRegionShippingService) return true
    }

    return totalPrice === undefined
  }, [addressRequired, selectedAddress, selectedRegionShippingService, totalPrice])

  const onClose = useCallback(() => {
    setShowOrderAddress?.(false)
    setShowBasket?.(true)
  }, [setShowBasket, setShowOrderAddress])

  const addressSelect = useMemo(() => {
    return <Controller
      control={ control }
      name='addressField'
      render={ ({ field }) => (
        <Select
          options={ addressFields }
          onChange={ val => field.onChange(val?.value) }
          placeholder={ t('defaults.select') }
          value={ addressFields.find(t => t.value === addressField?.value) }
          defaultValue={ addressFields.find(t => t.default) }
        />
      ) }
    />
    // return <select { ...register('addressField') }>
    //   {
    //     addressFields.map((field) => {
    //       return <option key={ field.value } value={ field.value }>{ field.label }</option>
    //     })
    //   }
    // </select>
  }, [addressField, addressFields, control, t])

  const addressesView = useMemo(() => {
    if (!isAuthenticated) return

    if (!addressRequired) return

    if (addressesLoading) {
      return (
        <p>{ t('checkout.buy_intent.addresses_loading') }</p>
      )
    }

    const newAddressLink: string = `/account/buy?modal=address&modal_callback=order_address&session_id=${sessionId}`

    if (!addresses || addresses.length === 0) {
      return (
        <>
          <p>{ t('checkout.buy_intent.shipping_none') }</p>
          <p><Link to={ newAddressLink } className="button full" onClick={ onClose }>{ t('checkout.buy_intent.shipping_none_new') }</Link></p>
        </>
      )
    }

    return <>
      <div className='select'>
        <label>
          { t('checkout.buy_intent.shipping_label') }
        </label>
        { addressSelect }
      </div>
      <p><label></label><Link to={ newAddressLink } className="button tool" onClick={ onClose }>{ t('checkout.buy_intent.shipping_new') }</Link></p>
    </>
  }, [isAuthenticated, addressRequired, addressesLoading, sessionId, addresses, t, addressSelect, onClose])

  const pointsToUse = useMemo(() => {
    if (!currentUser || !totalPrice) return 0

    // minimum of order total and user points
    return Math.min(totalPrice, currentUser.points)
  }, [currentUser, totalPrice])

  const [usePoints, setUsePoints] = useState(false)

  const totalPriceWithPoints = useMemo(() => {
    if (!totalPrice) return

    if (!points || !usePoints) return totalPrice

    return totalPrice - points
  }, [points, totalPrice, usePoints])

  const pointTextboxView = useMemo(() => {
    if (!usePoints) return

    return (
      <div className='input'>
        <label>
          { t('checkout.buy_intent.points_balance_to_use') }
        </label>
        <input
          type='number'
          min={ 0 }
          max={ pointsToUse }
          defaultValue={ pointsToUse }
          { ...register('points') }
        />
      </div>
    )
  }, [usePoints, pointsToUse, t, register])

  useEffect(() => {
    if (totalPrice === undefined) return

    if (points > totalPrice) {
      setValue('points', totalPrice)
    }
  }, [points, setValue, totalPrice])

  const togglePoints = useCallback((use: boolean) => {
    if (!use) {
      setValue('points', 0)
    } else {
      setValue('points', pointsToUse)
    }

    setUsePoints(!usePoints)
  }, [pointsToUse, setValue, usePoints])

  const pointView = useMemo(() => {
    if (pointsToUse <= 0) return

    // checkbox to use points
    return (
      <>
        <p className='noflex'>
          <label>HOLICポイント：</label>
          <label>
            { t('checkout.buy_intent.points_available', { points: pointsToUse }) }
          </label>
        </p>
        <p className='noflex'>
          <label></label>
          { /* <label>
            <input
              type='checkbox'
              checked={ usePoints }
              onChange={ e => togglePoints(e.target.checked) }
            />{ t('checkout.buy_intent.points_use') }
          </label> */ }
          <button className='button tool' onClick={ (e) => {
            e.preventDefault()
            togglePoints(!usePoints)
          } }>
            { usePoints ? t('checkout.buy_intent.points_not_use') : t('checkout.buy_intent.points_use') }
          </button>
        </p>
        { pointTextboxView }
      </>
    )
  }, [pointsToUse, usePoints, t, pointTextboxView, togglePoints])

  const canProceedWithPrice = useMemo(() => {
    if (totalPriceWithPoints === undefined) return false

    if (totalPriceWithPoints > 0 && totalPriceWithPoints < minimumPriceForStripe) return false

    return true
  }, [totalPriceWithPoints])

  const totalPriceView = useMemo(() => {
    if (addressRequired && !shippingAvailable) return

    if (totalPriceWithPoints === undefined) {
      return (
        <p>
          { t('checkout.buy_intent.shipping_select') }
        </p>
      )
    }

    if (!canProceedWithPrice) {
      return (
        <p>
          { t('checkout.buy_intent.order_minimum', { amount: minimumPriceForStripe }) }
        </p>
      )
    }

    return (
      <p>
        <span>{ t('checkout.buy_intent.total') }</span> { t('listings.price.price_with_currency_symbol', { price: Math.round(totalPriceWithPoints).toLocaleString() }) }
      </p>
    )
  }, [addressRequired, canProceedWithPrice, shippingAvailable, t, totalPriceWithPoints])

  const paymentProceedButton = useMemo(() => {
    if ((!addressRequired || shippingAvailable) && canProceedWithPrice) {
      return (
        <>
          <hr />
          <p>
            <button className="full" disabled= { payButtonDisabled }>
              { totalPrice && totalPrice > 0 ? t('checkout.buy_intent.button_pay') : '進む' }
            </button>
          </p>
        </>
      )
    }
  }, [addressRequired, canProceedWithPrice, payButtonDisabled, shippingAvailable, t, totalPrice])

  return (
    <ModalWrapper>
      <div>
        <h1>{ t('checkout.buy_intent.title') }</h1>
        <UnitOrderSummary onClose={ onClose } canDelete={ false } canView={ false } />
        <form onSubmit={ handleSubmit(onSubmit) } className="single-line">
          { addressesView }
          { shippingMethods }
          { pointView }
          { totalPriceView }
          { paymentProceedButton }
          <p>
            <button className="full white" onClick={ onClose }>{ t('checkout.buy_intent.button_cancel') }</button>
          </p>
        </form>
      </div>
    </ModalWrapper>
  )
}

export { UnitBuyIntentModal }
