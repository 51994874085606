import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { useContext, useEffect, useMemo, useState } from 'react'
import { BasketContext } from '../contexts/basket_context'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Order } from '../types'
import { PaymentMethodChangeCompleteModal } from './payment_method_change_complete_modal'

type SubscriptionChangePaymentMethodFormProps = Readonly<{
  order: Order
  orderId: string
}>

function SubscriptionChangePaymentMethodForm({ order, orderId }: SubscriptionChangePaymentMethodFormProps) {
  const { t } = useTranslation()

  const [formLoading, setFormLoading] = useState(false)
  const [showCompleteModal, setShowCompleteModal] = useState(false)

  const { setShowOrderLoading } = useContext(BasketContext)
  
  const stripe = useStripe()
  const elements = useElements()

  const backButton = useMemo(() => {
    return `/orders/${orderId}`
  }, [order.id])

  useEffect(() => {
    setShowOrderLoading?.(formLoading)
  }, [formLoading])

  const submitPayment = async (e) => {
    e.preventDefault()

    if (formLoading) {
      return
    }

    if (!stripe || !elements) {
      return
    }

    setFormLoading(true)

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}${backButton}?result=payment_success`,
      },
      redirect: 'if_required',
    })

    if (error) {
      console.error(error)
      setFormLoading(false)
      return
    } else {
      setShowCompleteModal(true)
    }

    setFormLoading(false)
  }

  return <>
    {
      showCompleteModal
        ? <PaymentMethodChangeCompleteModal orderId={ orderId } />
        : null
    }
    <div className='narrow'>
      <h1>{t('checkout.subscriptions.change_payment_method.title')}</h1>
      <form onSubmit={ submitPayment }>
        <PaymentElement />
        <p>
          <button className='full' type="submit" disabled={ formLoading }>{
            formLoading
              ? t('checkout.subscriptions.loading')
              : t('checkout.subscriptions.change_payment_method.button')
          }</button>
        </p>
        {
          !formLoading
            ? <p>
              <Link to={ backButton } className='button full white'>{ t('checkout.subscriptions.change_payment_method.button_cancel') }</Link>
            </p>
            : null
        }
      </form>
    </div>
  </>
}

export { SubscriptionChangePaymentMethodForm }
