import axios from 'axios'
import applyConverters from 'axios-case-converter'
import Qs from 'qs'
import { setLocalIsAuthenticated } from '../lib/local_storage'
import { i18n } from '../components/i18n'

const _config = {
  headers: {
    'x-requested-with': 'XMLHttpRequest',
    accept: 'application/json',
    'accept-language': i18n.language,
  },
  withCredentials: true,
  paramsSerializer: (params: any) => Qs.stringify(params, { arrayFormat: 'brackets' }),
}

const axiosInstance = axios.create(_config)
applyConverters(axiosInstance, { preservedKeys: ['_destroy'] })

// TODO: Switch endpoints by env
if (process.env.REACT_APP_RAILS_ENV === 'development' || !process.env.REACT_APP_API_PREFIX || !process.env.REACT_APP_API_SUFFIX) {
  axiosInstance.defaults.baseURL = process.env.REACT_APP_API_BASE_URL
} else {
  const hostname = window.location.hostname.replace('integration.', '')
  axiosInstance.defaults.baseURL = process.env.REACT_APP_API_PREFIX + hostname + process.env.REACT_APP_API_SUFFIX
}

axiosInstance.defaults.xsrfCookieName = `CSRF-TOKEN_${String(process.env.REACT_APP_RAILS_ENV)}`
axiosInstance.defaults.xsrfHeaderName = 'X-CSRF-TOKEN'
axiosInstance.defaults.withCredentials = true

axiosInstance.interceptors.request.use(
  request => request,
  async error => await Promise.reject(error)
)

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401) {
      setLocalIsAuthenticated(false)
      if (error?.config.url !== '/sessions') {
        alert('ログインしてください')
        location.href = '/login'
      }
    }
    return await Promise.reject(error)
  }
)

export { axiosInstance }
