import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { useContext, useEffect, useMemo, useState } from 'react'
import { BasketContext } from '../contexts/basket_context'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Order } from '../types'

type SubscriptionPaymentFormProps = Readonly<{
  order: Order
  orderId: string
}>

function SubscriptionPaymentForm({ order, orderId }: SubscriptionPaymentFormProps) {
  const { t } = useTranslation()

  const [formLoading, setFormLoading] = useState(false)

  const { setShowOrderLoading } = useContext(BasketContext)
  
  const stripe = useStripe()
  const elements = useElements()

  const firstUnit = useMemo(() => {
    return order.units?.[0]
  }, [order.units])

  const backButton = useMemo(() => {
    if (!firstUnit) {
      return '/orders/latest'
    }

    if (isNaN(Number(orderId))) {
      // go back to the first unit in the order
      return `/u/${firstUnit?.userId}/units/${firstUnit?.id}`
    }

    return `/orders/${orderId}`
  }, [order.id])

  const callbackEndpoint = useMemo(() => {
    return isNaN(Number(orderId)) ? '/orders/latest' : `/orders/${orderId}`
  }, [orderId])

  useEffect(() => {
    setShowOrderLoading?.(formLoading)
  }, [formLoading])

  const submitPayment = async (e) => {
    e.preventDefault()

    if (formLoading) {
      return
    }

    if (!stripe || !elements) {
      return
    }

    setFormLoading(true)

    const returnUrl = process.env.NODE_ENV === 'production'
      ? 'https://holic.net'
      : 'http://lvh.me:3001'

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${returnUrl}${callbackEndpoint}?result=success`,
      },
    })

    if (error) {
      console.error(error)
      setFormLoading(false)
      return
    }
  }

  const orderName = useMemo(() => {
    if (order.orderListings) {
      return order.orderListings?.map(ol => {
        const unit = order.units?.find(unit => unit.listings?.some(l => l.id === ol.listingId))
        
        if (!unit) return null

        if (ol.quantity && ol.quantity > 1) {
          return `${ol.quantity}x ${unit.items?.map(i => i.nameJa).join(', ')}`
        }

        return unit.items?.map(i => i.nameJa).join(', ')
      }).join(', ')
    }

    return order.units?.map((unit) => unit.items?.map((i) => i.nameJa).join(', ')).join(', ')
  }, [order])
  
  const [maxIntervalCount, setMaxIntervalCount] = useState(0)
  const [nextBillingDate, setNextBillingDate] = useState(new Date())

  useEffect(() => {
    const intervalCounts = order.units?.map(unit => unit.listings?.map(listing => listing.intervalCount ?? 0).reduce((a, b) => Math.max(a, b), 0) ?? 0) ?? []
    const maxIntervalCountValue = Math.max(...intervalCounts)
    setMaxIntervalCount(maxIntervalCountValue)

    const newBillingDate = new Date()
    newBillingDate.setDate(newBillingDate.getDate() + maxIntervalCountValue)
    setNextBillingDate(newBillingDate)
  }, [order])

  return <div className='narrow'>
    <h1>購入内容の確認</h1>
    <h3>{ orderName }</h3>
    <ul className='organised'>
      <li>
        <span>
          請求金額：
        </span>
        <span>
          { Math.round(order.itemPriceJpy ?? 0).toLocaleString() }円（税込）
        </span>
      </li>
      <li>
        <span>
          初回請求日：
        </span>
        <span>
          { new Date().getFullYear() }年{ new Date().getMonth() + 1 }月{ new Date().getDate() }日
        </span>
      </li>
      <li>
        <span>
          次回請求：
        </span>
        <span>
          { nextBillingDate.getMonth() + 1 }月{ nextBillingDate.getDate() }日以降{ maxIntervalCount }日毎
        </span>
      </li>
      <li className='small'>{ t('checkout.subscriptions.subscription_cancel_information', { days: maxIntervalCount }) }</li>
    </ul>
    <hr />
    <form onSubmit={ submitPayment }>
      <PaymentElement />
      <p>
        <button className='full' type="submit" disabled={ formLoading }>{
          formLoading
            ? t('checkout.subscriptions.loading')
            : t('checkout.subscriptions.confirm_subscription')
        }</button>
      </p>
      {
        !formLoading
          ? <p>
            <Link to={ backButton } className='button full white'>{ t('checkout.subscriptions.cancel') }</Link>
          </p>
          : null
      }
    </form>
  </div>
}

export { SubscriptionPaymentForm }
