import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useMatch } from 'react-router-dom'
import { ordersRepository } from '../repositories/orders_repository'
import { ErrorExpired } from './error_expired'
import { Order } from '../types'
import { SubscriptionPaymentForm } from './subscription_payment_form'
import { SubscriptionChangePaymentMethodForm } from './subscription_change_payment_method_form'

const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE_PUBLIC_KEY))

const Subscriptions = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [order, setOrder] = useState<Order>()
  const [options, setOptions] = useState<Record<string, string>>({})

  const params = useMatch('/subscriptions/:id')?.params

  const orderId = useMemo<string>(() => {
    return params?.id ?? ''
  }, [params])

  const location = useLocation()
  const isEditPayment = useMemo<boolean>(() => {
    const searchParams = new URLSearchParams(location.search)
    return searchParams.get('edit') === 'payment'
  }, [location.search])

  // get the order
  useEffect(() => {
    if (!orderId) {
      return
    }

    // attempt to get the order
    // will try 10 times with a 1 second interval
    // after 10 attempts, will try every 5 seconds for 10 more attempts
    let attempts = 0
    let interval = 1000
    const maxAttempts = 20

    const fetchOrder = () => {
      ordersRepository
        .get(orderId)
        .then((res) => {
          // an order was found
          attempts = maxAttempts

          setOrder(res.order)

          if (res.order.status === 'incomplete' || res.order.status === 'incomplete_expired') {
            setOptions({
              clientSecret: res.order.providerSecret ?? '',
            })
          }
        })
        .finally(() => {
          attempts += 1
          if (attempts < maxAttempts) {
            if (attempts >= maxAttempts / 2) {
              interval = 5000
            }

            setTimeout(fetchOrder, interval)
          } else {
            if (!isEditPayment) {
              setLoading(false)
            }
          }
        })
    }

    fetchOrder()
  }, [orderId])

  useEffect(() => {
    if (order && !isEditPayment && order?.status !== 'incomplete') {
      // redirect to the order page
      window.location.href = `/orders/${order.id}`
    }

    if (!order || !isEditPayment) {
      return
    }

    // prepare a new payment method
    ordersRepository
      .preparePaymentMethod(String(order.id))
      .then((res) => {
        console.log(res.clientSecret)

        setOptions({
          clientSecret: res.clientSecret,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [order, isEditPayment])

  if (!stripePromise) {
    console.error('Stripe promise or client secret not found')
    return <></>
  }

  if (loading) {
    return <p>
      Loading...
    </p>
  }

  if (order && isEditPayment) {
    return <Elements stripe={stripePromise} options={options}>
      <SubscriptionChangePaymentMethodForm order={order} orderId={orderId} />
    </Elements>
  }

  if (order?.status !== 'incomplete' && order?.status !== 'incomplete_expired') {
    // expired form
    return <ErrorExpired />
  }

  return <Elements stripe={stripePromise} options={options}>
    <SubscriptionPaymentForm order={order} orderId={orderId} />
  </Elements>
}

export { Subscriptions }
