import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { BasketUnit, OrderCreate, Unit } from '../types'
import { AuthContext } from './auth_context'
import { basketRepository } from '../repositories/basket_repository'
import { AxiosError } from 'axios'
import { ErrorsContext } from './errors_context'
import { stripeCheckoutSessionsRepository } from '../repositories/stripe_checkout_sessions_repository'
import { getLocalReferral, setLocalReferral } from '../lib/local_storage'

// type OrderCreate = Readonly<{
//   address?: Address
//   regionShippingService?: RegionShippingService
//   listings?: Listing[]
//   addressField?: SelectField
//   regionShippingServiceField?: SelectField
//   returnPath?: string
//   feedbacks?: Feedback[]
//   referral?: string
// }>

const BasketContext = createContext<{
  basketUnits: BasketUnit[]
  addUnitToBasket?: (unit: Unit, overwrite: boolean, quantity: number, mode: string) => void
  removeUnitFromBasket?: (unit: Unit) => void
  clearBasket?: () => void
  closeOrderError?: () => void
  showBasket: boolean
  setShowBasket?: (showBasket: boolean) => void
  showOrderAddress: boolean
  setShowOrderAddress?: (show: boolean) => void
  setPreparedOrder?: (order: OrderCreate) => void
  showOrderLoading: boolean
  setShowOrderLoading?: (show: boolean) => void
  showOrderError?: string
  showOrderComplete: boolean
  basketAddedMessage?: Unit
  referral: string
  setReferral?: (value: string) => void
}>({
      basketUnits: [],
      showBasket: false,
      showOrderAddress: false,
      showOrderLoading: false,
      showOrderComplete: false,
      referral: getLocalReferral(),
    })

const basketLocalStorageKey = 'basket'

const BasketProvider = ({ children }) => {
  const { addError } = useContext(ErrorsContext)
  const { isAuthenticated, currentUser } = useContext(AuthContext)

  const [showBasket, _setShowBasket] = useState<boolean>(false)
  const [order, _setPreparedOrder] = useState<OrderCreate|undefined>()
  const [showOrderAddress, _setShowOrderAddress] = useState<boolean>(false)
  const [showOrderLoading, _setShowOrderLoading] = useState<boolean>(false)
  const [showOrderError, _setShowOrderError] = useState<string|undefined>()
  const [showOrderComplete, _setShowOrderComplete] = useState<boolean>(false)
  const [basketAddedMessage, _setBasketAddedMessage] = useState<Unit|undefined>()

  const [_basketInitiated, _setBasketInitiated] = useState<boolean>(false)

  const _localStorageItems = useCallback(() => {
    const items = window.localStorage.getItem(basketLocalStorageKey)

    return items !== null
      ? JSON.parse(items)
      : []
  }, [])

  const [basketUnits, setBasketUnits] = useState<BasketUnit[]>(() => {
    if (!isAuthenticated) {
      return _localStorageItems()
    }

    return []
  })

  const clearBasket = useCallback(() => {
    setBasketUnits([])

    // sync with local storage
    window.localStorage.removeItem(basketLocalStorageKey)

    // sync with database
    if (isAuthenticated) {
      basketRepository
        .delete_all()
        .catch((err: AxiosError) => {
          console.error(err)
        })
    }
  }, [isAuthenticated])

  const closeOrderError = useCallback(() => {
    _setShowOrderError(undefined)
  }, [])

  const setShowBasket = useCallback((showBasket) => {
    _setShowBasket(showBasket)
    _setShowOrderComplete(false)
  }, [])

  useEffect(() => {
    if (!showBasket) _setBasketAddedMessage(undefined)
  }, [showBasket])

  const setShowOrderAddress = useCallback((show) => {
    _setShowOrderAddress(show)
  }, [])

  const setPreparedOrder = useCallback((order) => {
    _setPreparedOrder(order)
  }, [])

  const setShowOrderLoading = useCallback((show) => {
    _setShowOrderLoading(show)
  }, [])

  const addressRequired = useMemo(() => {
    if (!basketUnits) return true

    // determine if a basket item requires an address
    return basketUnits.some(basketUnit => basketUnit.unit.items?.some(item => item.type !== 'DigitalDownload'))
  }, [basketUnits])

  const createOrder = useCallback(async () => {
    if (order === undefined) {
      alert('Error')
      return
    }

    if (addressRequired && (!order.regionShippingService || !order.address)) {
      alert('Please select a shipping address')
      return
    }

    const stripe = await loadStripe(String(process.env.REACT_APP_STRIPE_PUBLIC_KEY))

    if (stripe === null) {
      alert('Failed to load Stripe. Please reload your browser')
      return
    }

    const mode = order.mode ?? 'payment'

    const sessionData = await stripeCheckoutSessionsRepository.create({
      addressId: addressRequired ? Number(order.address?.id) : undefined,
      regionShippingServiceId: addressRequired ? order.regionShippingService?.id : undefined,
      returnPath: order.returnPath ?? '',
      referral: order.referral ?? '',
      points: order.points ?? 0,
      mode,
    })
      .catch((err) => {
        addError?.(err)
        _setShowOrderLoading(false)
        _setShowOrderError('The item is no longer available to purchase.')
      })

    if (sessionData) {
      const sessionId = sessionData.sessionId
      const clientSecret = sessionData.clientSecret

      if (mode === 'subscription') {
        // move to the subscription payment page
        if (!clientSecret) {
          alert('エラーが発生しました')
          return
        }

        // clear basket
        setBasketUnits([])

        window.location.href = `/subscriptions/${sessionId}`
        return
      } else if (mode === 'payment') {
        if (!sessionId) {
          alert('エラーが発生しました')
          return
        }

        stripe.redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as argument here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: sessionId
        }).then((result) => {
          alert(result.error.message)
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          _setShowOrderLoading(false)
        })
      } else {
        _setShowOrderLoading(false)
        _setShowOrderComplete(true)
        setBasketUnits([])
      }
    }
  }, [addError, addressRequired, order])

  useEffect(() => {
    if (order) {
      // move to Stripe
      _setShowOrderLoading(true)
      createOrder()
    }
  }, [createOrder, order])

  const currentBasketUnitsSeller = useMemo(() => basketUnits.length > 0 ? basketUnits[0].unit.userId : undefined, [basketUnits])

  const _addToDb = useCallback((unitId, quantity, mode) => {
    return basketRepository
      .create({
        unitId,
        quantity,
        mode,
      })
      .then(() => {
        return true
      })
      .catch((err: AxiosError) => {
        alert('エラーが発生しました')
        console.error(err)

        // remove the item from the basket
        setBasketUnits(basketUnit => basketUnit.filter(bu => bu.unit.id !== unitId))

        // refresh the page
        window.location.reload()

        return false
      })
  }, [addError])

  const _removeFromDb = useCallback((unitId) => {
    return basketRepository
      .delete(unitId)
      .then(() => {
        return true
      })
      .catch((err: AxiosError) => {
        return false
      })
  }, [addError])

  const addUnitToBasket = useCallback((unit: Unit, overwrite: boolean, quantity: number, mode: string) => {
    let inBasket = basketUnits.find(basketUnit => basketUnit.unit.id === unit.id)

    const quantityNew = mode === 'subscription' ? 1 : quantity

    if (currentBasketUnitsSeller && unit.userId !== currentBasketUnitsSeller && !overwrite) {
      // For phase 1, a user can only add one seller's items to their basket
      return false
    }

    // if the basket has items with different modes, don't allow adding
    if (basketUnits.length > 0 && basketUnits.some(basketUnit => basketUnit.mode !== mode) && !overwrite) {
      return false
    }

    const basketUnit = {
      unit,
      quantity: quantityNew,
      mode,
    }

    // toggle mode if the unit is already in the basket
    if (inBasket && inBasket.mode !== mode && !overwrite) {
      setBasketUnits(bu => {
        return [
          ...bu.filter(bu => bu.unit.id !== unit.id),
          {
            unit,
            quantity: quantityNew,
            mode,
          },
        ]
      })

      if (isAuthenticated) {
        _addToDb(unit.id, quantity, mode)
      }
    } else if (overwrite) {
      setBasketUnits([basketUnit])
      
      if (isAuthenticated) {
        basketRepository
          .delete_all()
          .then(() => {
            _addToDb(unit.id, quantity, mode)
          })
          .catch((err: AxiosError) => {
            console.error(err)
          })
      } else {
        window.localStorage.setItem(basketLocalStorageKey, JSON.stringify([basketUnit]))
      }
    } else if (!inBasket || inBasket.quantity !== quantity) {
      // setBasketUnits(i => [...i ?? [], basketUnit])

      // if the unit is already in the basket, update the quantity
      setBasketUnits(basketUnit => {
        return [
          ...basketUnit.filter(bu => bu.unit.id !== unit.id),
          {
            unit,
            quantity,
            mode,
          },
        ]
      })

      _setBasketAddedMessage(unit)

      if (isAuthenticated) {
        _addToDb(unit.id, quantity, mode)
      }
    }

    return true
  }, [_addToDb, basketUnits, currentBasketUnitsSeller, isAuthenticated])

  const removeUnitFromBasket = useCallback((unit: Unit) => {
    const inBasket = basketUnits.find(basketUnit => basketUnit.unit.id === unit.id)

    if (inBasket) {
      setBasketUnits(basketUnit => basketUnit.filter(bu => bu.unit.id !== unit.id))

      if (isAuthenticated) {
        _removeFromDb(unit.id)
      }
    }

    _setBasketAddedMessage(undefined)

    return true
  }, [_removeFromDb, basketUnits, isAuthenticated])

  useEffect(() => {
    if (!isAuthenticated) {
      window.localStorage.setItem(basketLocalStorageKey, JSON.stringify(basketUnits))
    }
  }, [basketUnits, isAuthenticated])

  useEffect(() => {
    if (currentUser && !_basketInitiated) {
      setBasketUnits(currentUser?.basket)
      _setBasketInitiated(true)
    }
  }, [_basketInitiated, currentUser])

  useEffect(() => {
    if (_basketInitiated) {
      const ls = _localStorageItems()

      if (ls.length > 0) {
        // user has localstorage items; move to database
        for (let i = 0; i < ls.length; ++i) {
          const container = ls[i]

          if (!container.unit) continue

          const quantity = container.quantity ?? 1
          const mode = container.mode ?? 'payment'

          addUnitToBasket(container.unit, false, quantity, mode)
        }

        window.localStorage.removeItem(basketLocalStorageKey)

        setShowBasket(true)
      }
    }
  }, [_basketInitiated, _localStorageItems, addUnitToBasket, setShowBasket])

  const [referral, _setReferral] = useState<string>(getLocalReferral())

  const setReferral = useCallback((value: string) => {
    _setReferral(value)
    setLocalReferral(value)
  }, [])

  return (
    <BasketContext.Provider value={ { basketUnits, addUnitToBasket, removeUnitFromBasket, clearBasket, setShowBasket, showBasket, showOrderAddress, setShowOrderAddress, setPreparedOrder, showOrderLoading, showOrderError, closeOrderError, showOrderComplete, basketAddedMessage, referral, setReferral, setShowOrderLoading } }>
      { children }
    </BasketContext.Provider>
  )
}

export { BasketProvider, BasketContext }
