import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ModalWrapper } from './modal_wrapper'

type PaymentMethodChangeCompleteModalProps = {
  orderId: string
}

const PaymentMethodChangeCompleteModal: React.FC<PaymentMethodChangeCompleteModalProps> = ({ orderId }) => {
  const { t } = useTranslation()

  return (
    <ModalWrapper>
      <h1>{t('checkout.subscriptions.change_payment_method.success_modal.title')}</h1>
      <p>{t('checkout.subscriptions.change_payment_method.success_modal.description')}</p>
      <p>
        <Link to={`/orders/${orderId}`} className="button full">
          {t('checkout.subscriptions.change_payment_method.success_modal.button')}
        </Link>
      </p>
    </ModalWrapper>
  )
}

export { PaymentMethodChangeCompleteModal }
