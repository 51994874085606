import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link, Navigate, useMatch } from 'react-router-dom'
import queryString from 'query-string'
import { GenericNotFound } from './generic_not_found'
import { AuthContext } from '../contexts/auth_context'
import { Feedback, Message, Order, OrderUpdate } from '../types'
import { ordersRepository } from '../repositories/orders_repository'
import { AxiosError } from 'axios'
import { ErrorsContext } from '../contexts/errors_context'
import { useTranslation } from 'react-i18next'
import { conditions } from '../lib/conditions'
import { countries } from '../lib/countries'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { OrderFeedback } from './order_feedback'

import '../stylesheets/Messages.scss'
import { OrderMessages } from './order_messages'
import Select from 'react-select'
import { SearchContext } from '../contexts/search_context'
import { UnitBuyCompleteModal } from './unit_buy_complete_modal'
import { ModalWrapper } from './modal_wrapper'
import { BasketContext } from '../contexts/basket_context'

const SubscribeCancelIntentModal = ({ order, onClose }: { order: Order, onClose: (status: string) => void }) => {
  const { t } = useTranslation()
  const { addError } = useContext(ErrorsContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [status, setStatus] = useState<string>('')

  const confirmChangeSubscriptionStatus = useCallback(() => {
    if (!order?.id || loading) return

    setLoading(true)

    const newStatus = order.status === 'active' || order.status === 'past_due' ? 'cancelled' : 'active'

    const orderUpdate: OrderUpdate = {
      id: order.id,
      status: newStatus,
    }

    ordersRepository
      .update({ id: order.id, params: orderUpdate })
      .then(() => {
        setStatus(newStatus)
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [loading, onClose, order.id])

  const expiryDate = useMemo(() => {
    return order?.expiryDate ? new Date(order.expiryDate).toLocaleDateString('ja-JP', { year: 'numeric', month: 'long', day: 'numeric' }) : ''
  }, [order?.expiryDate])

  useEffect(() => {
    if (!status && order.status === 'cancelled') {
      confirmChangeSubscriptionStatus()
    }
  }, [status, order.status, confirmChangeSubscriptionStatus])

  if (!status && order.status === 'cancelled') {
    return <ModalWrapper>
      <p>{t('order.view.loading')}</p>
    </ModalWrapper>
  }

  if (status === 'active') {
    return (
      <ModalWrapper>
        <p>{t('order.subscription.continue_thank_you')}</p>
        <p>{t('order.subscription.enjoy_komiflo')}</p>
        <p>
          <button className="full" onClick={() => onClose(status)}>
            {t('order.actions.close')}
          </button>
        </p>
      </ModalWrapper>
    )
  }

  if (status === 'cancelled') {
    return (
      <ModalWrapper>
        <h1>{t('order.subscription.cancel_confirmation')}</h1>
        <p>{t('order.subscription.cancel_procedure_complete')}</p>
        <p>{t('order.subscription.enjoy_until_expiry')}</p>
        <p>{t('order.subscription.subscription_ends_on', { expiryDate })}</p>
        <p>
          <button className="full" onClick={() => onClose(status)}>
            {t('order.actions.close')}
          </button>
        </p>
      </ModalWrapper>
    )
  }

  return (
    <ModalWrapper>
      <h1>{t('order.subscription.manage_plan')}</h1>
      <p>{t('order.subscription.confirm_cancel')}</p>
      {/* <p>{t('order.subscription.awaiting_next_use')}</p> */}
      <p>
        <button className="full" onClick={confirmChangeSubscriptionStatus} disabled={loading}>
          {t('order.actions.complete_cancel')}
        </button>
      </p>
      <p>
        <button className="full" onClick={() => {
          if (order.status === 'past_due') {
            onClose(order.status)
          } else {
            setStatus(order.status)
          }
        }} disabled={loading}>
          {t('order.actions.continue')}
        </button>
      </p>
    </ModalWrapper>
  )
}

type SubscriptionPlanSettingsModalProps = {
  setShowSubscriptionCancelIntent: (show: boolean) => void
  onClose: () => void
  orderId: number
}

const SubscriptionPlanSettingsModal = ({ setShowSubscriptionCancelIntent, onClose, orderId }: SubscriptionPlanSettingsModalProps) => {
  const { t } = useTranslation()
  const { currentUser, reloadCurrentUser } = useContext(AuthContext)

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (loading) return

    reloadCurrentUser?.()
      .finally(() => {
        setLoading(false)
      })
  }, [reloadCurrentUser])

  if (!currentUser || loading) return <ModalWrapper>
    <p>{t('subscription_plan_settings.loading')}</p>
  </ModalWrapper>

  return (
    <ModalWrapper>
      <h1>{t('subscription_plan_settings.title')}</h1>
      <ul className="organised narrow">
        <li>
          <span>{t('subscription_plan_settings.brand')}</span>
          {t(`subscription_plan_settings.card_brands.${currentUser.cardBrand}`)}
        </li>
        <li>
          <span>{t('subscription_plan_settings.last_four')}</span>
          {currentUser.cardLast4}
        </li>
        <li>
          <span>{t('subscription_plan_settings.expiry')}</span>
          {currentUser.cardExpYear}/{currentUser.cardExpMonth?.toString().padStart(2, '0')}
        </li>
      </ul>
      <p>
        <Link to={`/subscriptions/${orderId}?edit=payment`} className="full white button">
          {t('subscription_plan_settings.payment_settings')}
        </Link>
      </p>
      <p>
        <button className="full white" onClick={() => {
          setShowSubscriptionCancelIntent(true)
          onClose()
        }}>
          {t('subscription_plan_settings.cancel_plan')}
        </button>
      </p>
      <p>
        <button className="full" onClick={onClose}>
          {t('subscription_plan_settings.close')}
        </button>
      </p>
    </ModalWrapper>
  )
}

type Status = Readonly<{
  value: string
  label: string
}>

const Orders = () => {
  const { t, i18n } = useTranslation()
  const { addError } = useContext(ErrorsContext)
  const { clearBasket } = useContext(BasketContext)
  
  const params = useMatch('/orders/:id')?.params

  const orderId = useMemo<number|'latest'>(() => {
    if (params === undefined) return 0

    return params.id === 'latest' ? 'latest' : parseInt(params.id ?? '0') || 0
  }, [params])

  const qS = queryString.parse(location.search)
  const result = qS.result as string
  const [showOrderCompleteModal, setShowOrderCompleteModal] = useState<boolean>(result === 'success')

  useEffect(() => {
    if (orderId === 'latest' && showOrderCompleteModal) {
      clearBasket?.()
    }
  }, [orderId, clearBasket, showOrderCompleteModal])

  const [order, setOrder] = useState<Order|undefined>()
  const [orderLoading, setOrderLoading] = useState<boolean>(true)
  const [statusUpdateLoading, setStatusUpdateLoading] = useState<boolean>(false)

  const { isAuthenticated } = useContext(AuthContext)

  const getOrder = useCallback(() => {
    setOrderLoading(true)

    ordersRepository
      .get(String(orderId))
      .then(({ order }) => {
        let status = order.status

        if (showOrderCompleteModal && order.startDate && (order.status === 'incomplete' || order.status === 'incomplete_expired')) {
          // if the user has just completed a successful payment and the subscription status is pending, force the status to "active"
          status = 'active'
        }

        if (order.status === 'past_due' && result === 'payment_success') {
          status = 'pending'
        }

        setOrder({
          ...order,
          status,
        })
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setOrderLoading(false)
      })
  }, [addError, orderId])

  useEffect(() => {
    getOrder()
  }, [getOrder])

  const isSubscription = useMemo(() => {
    return order?.startDate !== null
  }, [order?.startDate])

  const totalPrice = useMemo(() => {
    if (!order) return 0

    return +(order.itemPriceJpy ?? 0) + +(order.shippingPriceJpy ?? 0)
  }, [order])

  const country = useMemo(() => {
    if (!order?.address?.countryCode) return

    return countries.find(c => c.value === order?.address?.countryCode)
  }, [order])

  const support = useMemo(() => {
    if (order?.role !== 'buyer') return

    return <>
      <hr />
      <p className='center'>
        <a href="mailto:support@holic.net" className="button tool">{ t('order.view.help') }</a>
      </p>
    </>
  }, [order?.role, t])

  const {
    handleSubmit,
    control,
  } = useForm<OrderUpdate>()

  const status = useWatch({
    control,
    name: 'status',
  })

  const onSubmit = useCallback((params) => {
    if (!order?.id || statusUpdateLoading) return

    // TODO: Validation
    const orderUpdate: OrderUpdate = { ...params }

    ordersRepository
      .update({ id: order.id, params: orderUpdate })
      .then(() => {
        setOrder({
          ...order,
          status: params.status
        })
        alert(t('order.view.status_updated'))
      })
      .catch((err: AxiosError) => {
        addError?.(err)
      })
      .finally(() => {
        setStatusUpdateLoading(false)
      })

    setStatusUpdateLoading(true)
  }, [addError, order, statusUpdateLoading, t])

  const updateOrder = useMemo(() => {
    if (order?.status === 'cancelled') return

    if (order?.role !== 'seller') return

    const statuses: Status[] = [
      {
        label: t('order.view.status_shipped'),
        value: 'shipped',
      },
      {
        label: t('order.view.status_succeeded'),
        value: 'succeeded',
      },
    ]

    return (
      <>
        <hr />
        <form onSubmit={ handleSubmit(onSubmit) }>
          <h3>{ t('order.view.status_update') }</h3>
          <div className="select">
            <Controller
              control={ control }
              name='status'
              render={ ({ field }) => (
                <Select
                  options={ statuses }
                  onChange={ val => field.onChange(val?.value) }
                  placeholder={ t('defaults.select') }
                  value={ statuses.find(t => t.value === status) }
                />
              ) }
            />
          </div>
          <p>
            <button className="full" disabled={ statusUpdateLoading }>{ t('order.view.status_save') }</button>
          </p>
        </form>
      </>
    )
  }, [control, handleSubmit, onSubmit, order?.role, order?.status, status, statusUpdateLoading, t])

  const [showSubscriptionCancelIntent, setShowSubscriptionCancelIntent] = useState<boolean>(false)
  const [showPlanSettings, setShowPlanSettings] = useState<boolean>(false)
  
  const { filters, endpointForExistingFilters } = useContext(SearchContext)
  const categoriesLink = useCallback((tagId: number) => {
    if (!endpointForExistingFilters) return ''

    const p = `/categories/${tagId}`

    const params = endpointForExistingFilters(filters, '')

    return p + params
  }, [endpointForExistingFilters, filters])

  const items = useMemo(() => {
    return order?.units?.map((u) => {
      if (!u.userId || !u.id) return null

      // TODO: should only have one listing here
      const listingPrice = u.listings?.map(l => l.priceJpy).reduce((previous, current) => previous + current, 0) ?? 0

      // const itemName = (i18n.language === 'ja' && ui.item?.nameJa) ?? !ui.item?.nameEn ? ui.item?.nameJa : ui.item?.nameEn

      const itemName = u.items?.map(i => {
        return (i18n.language === 'ja' && i.nameJa) ?? !i.nameEn ? i.nameJa : i.nameEn
      }).join(' / ') ?? ''

      const condition = conditions.find(c => c.id === u.condition)
      const conditionName = t(`collections.conditions.long.${condition?.id ?? 0}`)

      const tags = u.items?.flatMap(i => i.tags)
      const tag = tags && tags.length > 0 ? tags.reduce((previous, current) => current.depth > previous.depth && current.type === 'Category' ? current : previous) : undefined

      const tagUi: JSX.Element = !tag
        ? <></>
        : <span key={ tag.id }>- <Link to={ categoriesLink(tag.id) }>{
            (i18n.language === 'ja' && tag.nameJa) ?? !tag.nameEn ? tag.nameJa : tag.nameEn
          }</Link></span>

      return (
        <li key={ u.id }>
          <span><Link to={ `/u/${u.userId}/units/${u.id}` }>{ itemName }</Link> ({ conditionName }){ tagUi }</span>
          <span>{ Math.round(listingPrice) }</span>
        </li>
      )
    })
  }, [categoriesLink, i18n.language, order?.units, t])

  const shippingMethod = useMemo(() => {
    if (order?.role !== 'seller') return

    return (
      <>
        <p>
          { t('order.view.shipping_method') }<strong>{ t(`account.sell.shipping_options.options.${order?.regionShippingService?.shippingService.label ?? ''}`) }</strong>
        </p>
      </>
    )
  }, [order?.regionShippingService?.shippingService.label, order?.role, t])

  const returnsPolicy = useMemo(() => {
    if (!order) return

    return <>
      <hr />
      <ul className="organised">
        <li>
          <span>返品条件</span>
          {
            order.returns > 0
              ? order.returns === 2
                ? 'お客様都合での返品可 (一部返金)'
                : 'お客様都合での返品可 (全額返金)'
              : 'お客様都合での返品不可'
          }
        </li>
        <li>
          <span></span>
          <Link to='/about/returns'>詳細を確認する</Link>
        </li>
      </ul>
    </>
  }, [order])

  const feeSummary = useMemo(() => {
    if (!order || order.role !== 'seller') return

    const referralSummary = order.orderReferrals && order.orderReferrals.length > 0
      ? <>
        <h3>{ t('order.view.referral.title') }</h3>
        <table>
          <thead>
            <tr>
              <td>{ t('order.view.referral.headings.user') }</td>
              <td>{ t('order.view.referral.headings.status') }</td>
            </tr>
          </thead>
          <tbody>
            {
              order.orderReferrals.map((r) => {
                return (
                  <tr key={ r.id }>
                    <td>
                      {
                        r.referral.user
                          ? <Link to={ `/u/${r.referral.userId}` }>{ r.referral.user?.displayName ?? t('cards.item.default_display_name') }</Link>
                          : '?'
                      }
                    </td>
                    <td>{ t(`order.view.referral.status.${r.status}`) }</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </>
      : null

    return <>
      <hr />
      { referralSummary }
      { /* <p>この注文の販売手数料は{ Number(order.feeJpy) }円です。</p> */ }
    </>
  }, [order, t])

  const actionRequired = useMemo(() => order?.role === 'seller' && order?.status === 'succeeded', [order?.role, order?.status])

  const statusColor = useMemo(() => {
    if (order?.status === 'shipped' || order?.status === 'active') {
      return 'green'
    } else if ((order?.status === 'succeeded' && order?.role === 'buyer') || order?.status === 'pending') {
      return 'yellow'
    } else if (actionRequired || order?.status === 'incomplete' || order?.status === 'incomplete_expired') {
      return 'red'
    }

    return ''
  }, [actionRequired, order?.role, order?.status])

  const actionExplanation = useMemo(() => {
    if (!actionRequired) return

    return (
      <div className="attention">
        <p><strong>{ t('order.view.advice.ship.title') }</strong></p>
        <p>{ t('order.view.advice.ship.body1') }</p>
        <p>{ t('order.view.advice.ship.body2') }</p>
        <p>{ t('order.view.advice.ship.body3') }</p>
      </div>
    )
  }, [actionRequired, t])

  const newFeedbackCallback = useCallback((f: Feedback) => {
    if (!order) return

    const newOrder: Order = {
      ...order,
      feedbacks: [f]
    }

    setOrder(newOrder)
  }, [order])

  const newMessageCallback = useCallback((m: Message) => {
    if (!order) return

    const mMine = { ...m, isMe: true }

    const newOrder: Order = {
      ...order,
      messages: order.messages?.concat([mMine])
    }

    setOrder(newOrder)
  }, [order])

  const feedback = useMemo(() => {
    if (!order) return

    if (order?.role === 'seller' && (!order.feedbacks || order.feedbacks.length < 1)) {
      // sellers can only leave feedback after the buyer has left feedback
      return
    }

    if (order?.role === 'buyer') {
      if (isSubscription && order.status !== 'active' && order.status !== 'cancelled') {
        // subscription was never activated
        return
      } else if (!isSubscription && order.status !== 'shipped') {
        // not a subscription and the order has not been shipped
        return
      }
    }

    return <><hr /><OrderFeedback order={ order } callback={ (f) => newFeedbackCallback(f) } /></>
  }, [newFeedbackCallback, order])

  const subscriptionIsEditable = useMemo(() => {
    return isSubscription && (
      order?.status === 'active'
      || order?.status === 'past_due'
    )
  }, [isSubscription, order?.status])

  const subscriptionCanBeUncancelled = useMemo(() => {
    return isSubscription && order?.status === 'cancelled' && order?.expiryDate && new Date(order.expiryDate).getTime() >= Date.now() + 3600000
  }, [isSubscription, order?.expiryDate, order?.status])

  const resubscribeCta = useMemo(() => {
    if (subscriptionCanBeUncancelled || order?.status !== 'cancelled') return

    let link = '/u/431'
    const firstUnit = order?.units?.[0]

    if (firstUnit) {
      link = `/u/${firstUnit.userId}/units/${firstUnit.id}`
    }

    return <>
        <hr />
        <p><strong>ご利用のサブスクリプションの期限が切れました。</strong></p>
        <p>
          再度Komifloをご利用いただくには、以下のボタンよりサブスクリプションを有効にしてください。
        </p>
        <p className='center'>
          <Link to={ link } className='button full'>サブスクリプションを有効にする</Link>
        </p>
      </>
  }, [order?.status, subscriptionCanBeUncancelled])

  if (!isAuthenticated) return <Navigate to='/register' />

  if (orderLoading) {
    return <div>
      <h1>{ t('order.view.loading') }</h1>
    </div>
  }

  if (!order) {
    return <GenericNotFound />
  }

  return (
    <div>
      {
        showSubscriptionCancelIntent &&
          <SubscribeCancelIntentModal
            order={ order }
            onClose={ (status) => {
              setShowSubscriptionCancelIntent(false)
              if (status !== order.status) {
                getOrder()
              }
            } }
          />
      }
      {
        showPlanSettings &&
          <SubscriptionPlanSettingsModal
            setShowSubscriptionCancelIntent={ setShowSubscriptionCancelIntent }
            onClose={ () => setShowPlanSettings(false) }
            orderId={ order.id ?? 0 }
          />
      }
      {
        showOrderCompleteModal &&
          <UnitBuyCompleteModal onClose={ () => setShowOrderCompleteModal(false) } unit={ order?.units?.[0] } />
      }
      <div className="narrow">
        <p>
          {
            order.role === 'buyer'
              ? <>{ t('order.view.my_account') } &gt; <Link to="/account/buy">{ t('order.view.buying') }</Link></>
              : <>{ t('order.view.my_account') } &gt; <Link to="/account/sell">{ t('order.view.selling') }</Link></>
          }
        </p>
        <h2>{ t('order.view.title', { number: order.id }) }</h2>
        <ul className="organised">
          <li>
            <span>
              { t('order.view.date_created') }
            </span>
            <span>
              { order.createdAt ? new Date(order.createdAt).toISOString().split('T')[0] : '' }
            </span>
          </li>
          {/* <li>
            <span>
              { t('order.view.date_updated') }
            </span>
            <span>
              { order.updatedAt ? new Date(order.updatedAt).toISOString().split('T')[0] : '' }
            </span>
          </li> */}
          {
            subscriptionIsEditable &&
              <>
                <li>
                  <span>
                    次回決済日：
                  </span>
                  <span>
                      { order.expiryDate ? new Date(order.expiryDate).toISOString().split('T')[0] : '' }
                  </span>
                </li>
              </>
          }
          <li>
            <span>
              { t('order.view.status') }
            </span>
            <span>
              <strong className={ statusColor }>{
                order.role === 'buyer'
                  ? t(`account.order_status_buyer.${order.status}`)
                  : t(`account.order_status_seller.${order.status}`)
              }</strong>
              {
                order.expiryDate
                  ? '定期購入'
                  : ''
              }
            </span>
          </li>
          {
            subscriptionIsEditable &&
              <>
                <li>
                  <span></span>
                  <span>
                    <button className='tool small' onClick={ () => setShowPlanSettings(true) }>プランの設定</button>
                  </span>
                </li>
              </>
          }
          {
            subscriptionCanBeUncancelled
                ? <li>
                  <span></span>
                  <span>
                    <button className='tool small' onClick={ () => setShowSubscriptionCancelIntent(true) }>自動更新を有効にする</button>
                  </span>
                </li>
                : <></>
          }
        </ul>
        { resubscribeCta }
        {
          order.status === 'incomplete' || order.status === 'incomplete_expired'
            ? <p className='center'>
              <Link to={ `/subscriptions/${order.id}` } className='button full'>{ t('order.view.complete_payment') }</Link>
            </p>
            : order.status === 'past_due'
              ? <p className='center'>
                <Link to={ `/subscriptions/${order.id}?edit=payment` } className='button full'>{ t('subscription_plan_settings.payment_settings') }</Link>
              </p>
              : <></>
        }
        {
          order.status === 'pending'
            ? <p className='small'>
              お支払いが完了するまで1分ほどかかる場合があります。<br />
              現在のお支払い状況を表示するには、このページを更新してください。
            </p>
            : <></>
        }
        { actionExplanation }
        { feedback }
        <hr />
        <h3>{ t('order.view.contents') }</h3>
        <ul className="editable">
          { items }
          <li>
            <span>{ t('order.view.shipping_price') }</span>
            <span>{ t('listings.price.price_with_currency_symbol', { price: Math.round(order.shippingPriceJpy ?? 0) }) }</span>
          </li>
          <li>
            <span>{ t('order.view.total_price') }</span><span>{ t('listings.price.price_with_currency_symbol', { price: Math.round(totalPrice) }) }</span>
          </li>
        </ul>
        <h3>{ t('order.view.address.title') }</h3>
        <ul className="organised">
          { country && <li><span>{ t('order.view.address.country') }</span><span>{ t(`account.countries.${country.value}`) }</span></li> }
          { order.address?.zipCode && <li><span>{ t('order.view.address.zip_code') }</span><span>{ order.address.zipCode }</span></li> }
          { order.address?.state && <li><span>{ t('order.view.address.address') }</span><span>{ order.address.state }</span></li> }
          { order.address?.addressLine3 && <li><span>{ !order.address.addressLine1 && !order.address.addressLine2 && t('order.view.address.address') }</span><span>{ order.address.addressLine3 }</span></li> }
          { order.address?.addressLine2 && <li><span>{ !order.address.addressLine1 && t('order.view.address.address') }</span><span>{ order.address.addressLine2 }</span></li> }
          { order.address?.addressLine1 && <li><span></span><span>{ order.address.addressLine1 }</span></li> }
          { order.address?.name && <li><span>{ t('order.view.address.name') }</span><span>{ order.address.name }</span></li> }
          { order.address?.telephone && <li><span>{ t('order.view.address.phone') }</span><span>{ order.address.telephone }</span></li> }
        </ul>
        { shippingMethod }
        { returnsPolicy }
        { updateOrder }
        <hr />
        <OrderMessages orderId={ order.id ?? 0 } orderStatus={ order.status } messages={ order.messages } callback={ (m) => newMessageCallback(m) } role={ order.role ?? '' } />
        { support }
        { feeSummary }
              </div>
    </div>
  )
}

export { Orders }
