import clsx from 'clsx'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ratings } from '../lib/ratings'
import { Feedback } from '../types'

type Props = Readonly<{
  feedback: Feedback
}>

const FeedbackView = ({ feedback }: Props) => {
  const { t } = useTranslation()

  const rating = ratings.find(r => r.value === feedback.rating)

  if (!rating) return null

  const feedbackDate = useMemo(() => {
    if (!feedback.createdAt) return new Date()
    return new Date(feedback.createdAt)
  }, [feedback.createdAt])

  return <table key={ feedback.id } className={ clsx('review', rating.value) }>
    <tbody>
      <tr>
        <td>{ t('order.feedback.left_feedback') }</td>
        <td>{ rating.label } { t(`order.feedback.${rating.value}`) }</td>
      </tr>
      {
        feedback.description
          ? <tr>
            <td>{ t('order.feedback.left_review') }</td>
            <td>{ feedback.description }</td>
          </tr>
          : null
      }
      <tr>
        <td></td>
        <td>{ feedbackDate.toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit' }) }</td>
      </tr>
    </tbody>
  </table>
}

export { FeedbackView }
